<template>
  <div class="jh-container">
    <div class="jh-ui-header">
      <h1>채팅 대시보드</h1>
      <div class="is-right">
        <label class="jh-label">최근 업데이트 :</label>
        <span class="is-value is-txt-blue">{{ recentUpdateTime }}</span>
        <v-btn class="jh-btn is-icon" @click="selectMonitoringData"><i class="jh-icon-refresh"></i></v-btn>
      </div>
    </div>

    <div class="jh-cols is-pb-20">
      <!--왼쪽 프레임-->
      <div class="is-left-frame is-col-fix" style="width: 280px;">
        <div class="jh-form-wrap is-dashboard" style="height: 121px;">
          <h6>배정가능건수</h6>
          <div class="is-body">
            <em class="is-txt-red">{{ readyData.READY_COUNSELOR_CNT }}</em>
            <span class="jh-unit">/</span>
            <em class="is-txt-black">{{ readyData.READY_COUNSELOR_TOTALCNT }}</em>
          </div>
          <div class="is-detail">
            <span class="is-label">상담중인 상담사</span>
            <span class="is-value">{{ readyData.COUNSEL_COUNSELOR_CNT }}</span>
          </div>
        </div>

        <div class="jh-form-wrap is-dashboard">
          <ul>
            <li>
              <h6>인입건</h6>
              <span class="is-value is-txt-blue">{{ statusData.NUMBER_INCOMING}}</span>
            </li>
            <li>
              <h6>순 인입건</h6>
              <span class="is-value is-txt-blue">{{ statusData.NUMBER_CUSTOMER_REQUEST }}</span>
            </li>
          </ul>
        </div>

        <div class="jh-form-wrap is-status">
          <div class="is-header">
            <div class="is-left">
              <i class="jh-icon-state is-ready"></i>
              <h6>고객 평균대기시간</h6>
            </div>
            <div class="is-right">
              <v-menu ref="dropReady" :close-on-content-click="true" transition="slide-y-transition" offset-y bottom>
                <template v-slot:activator="{ on, attrs }"><v-btn class="jh-btn is-icon" v-bind="attrs" v-on="on"><i class="jh-icon-help-fill is-blue"></i></v-btn></template>
                <div class="jh-drop-menu">
                  <h6><i class="jh-icon-state is-ready"></i>고객 평균대기시간</h6>
                  <ul class="jh-list-dot">
                    <li><em>상담건 평균대기</em><br>상담 건의 평균 대기시간</li>
                    <li><em>포기건 평균대기</em><br>포기 건의 평균 대기시간</li>
                  </ul>
                  <p class="jh-info-txt"><b>평균대기시간 :</b> Average Wait Time</p>
                </div>
              </v-menu>
            </div>
          </div>
          <div class="is-body">
            <ul class="jh-list-dashboard">
              <li>
                <label>상담건 평균대기</label>
                <em>{{ statusData.AVG_AWT_RESPONSE }}</em>
              </li>
              <li>
                <label>포기건 평균대기</label>
                <em>{{ statusData.AVG_AWT_GIVE_UP }}</em>
              </li>
            </ul>
          </div>
          
          <div class="is-header">
            <div class="is-left">
              <i class="jh-icon-state is-counsel"></i>
              <h6>평균 상담처리시간</h6>
            </div>
            <div class="is-right">
              <v-menu ref="dropCounsel" :close-on-content-click="true" transition="slide-y-transition" offset-y bottom>
                <template v-slot:activator="{ on, attrs }"><v-btn class="jh-btn is-icon" v-bind="attrs" v-on="on"><i class="jh-icon-help-fill is-blue"></i></v-btn></template>
                <div class="jh-drop-menu">
                  <h6><i class="jh-icon-state is-counsel"></i>평균 상담처리시간</h6>
                  <ul class="jh-list-dot">
                    <li><em>평균 상담시간</em>(Average Talk Time)<br>평균 상담시간</li>
                    <li><em>평균 후처리시간</em>(Average Work Time)<br>평균 후처리(상담 종료 후 상담이력 저장까지) 시간</li>
                    <li><em>평균 처리시간</em>(Average Handle Time)<br>평균 처리시간(평균상담시간 + 평균후처리시간)</li>
                  </ul>
                </div>
              </v-menu>
            </div>
          </div>
          <div class="is-body">
            <ul class="jh-list-dashboard">
              <li>
                <label>평균 상담시간</label>
                <em>{{ statusData.AVG_CHATTING }}</em>
              </li>
              <li>
                <label>평균 후처리시간</label>
                <em>{{ statusData.AVG_AFTER_TREATMENT }}</em>
              </li>
              <li>
                <label>평균 처리시간</label>
                <em>{{ statusData.AVG_CHATTING_AFTER_TREATMENT }}</em>
              </li>
            </ul>
          </div>
          
          <div class="is-header">
            <div class="is-left">
              <i class="jh-icon-state is-complete"></i>
              <h6>상담현황</h6>
            </div>
            <div class="is-right">
              <v-menu ref="dropComplete" :close-on-content-click="true" transition="slide-y-transition" offset-y bottom>
                <template v-slot:activator="{ on, attrs }"><v-btn class="jh-btn is-icon" v-bind="attrs" v-on="on"><i class="jh-icon-help-fill is-blue"></i></v-btn></template>
                <div class="jh-drop-menu">
                  <h6><i class="jh-icon-state is-complete"></i>상담현황</h6>
                  <ul class="jh-list-dot">
                    <li><em>응답률 : 응답 / 순인입</em></li>
                    <li><em>응답 : 상담 + 후처리 + 상담완료 - 전달</em></li>
                    <li><em>순인입 : 대기중 + 응답 + 포기</em></li>
                  </ul>
                </div>
              </v-menu>
            </div>
          </div>
          <div class="is-body">
            <ul class="jh-list-dashboard">
              <li>
                <label>응답률</label>
                <p><em>{{ statusData.RATE_RESPONSE }}</em><span class="jh-unit">%</span></p>                
              </li>
              <li>
                <label>응답</label>
                <p><em>{{ statusData.NUMBER_TOT_PROGRESS }}</em><span class="jh-unit">건</span></p>  
              </li>
              <li>
                <label>순인입</label>
                <p><em>{{ statusData.NUMBER_COMPLETE }}</em><span class="jh-unit">건</span></p>  
              </li>
            </ul>
          </div>
          
          <div class="is-header">
            <div class="is-left">
              <i class="jh-icon-state is-giveup"></i>
              <h6>고객포기</h6>
            </div>
            <div class="is-right">
              <v-menu ref="dropGiveup" :close-on-content-click="true" transition="slide-y-transition" offset-y bottom>
                <template v-slot:activator="{ on, attrs }"><v-btn class="jh-btn is-icon" v-bind="attrs" v-on="on"><i class="jh-icon-help-fill is-blue"></i></v-btn></template>
                <div class="jh-drop-menu">
                  <h6><i class="jh-icon-state is-giveup"></i>고객포기</h6>
                  <ul class="jh-list-dot">
                    <li><em>상담 요청중 포기</em><br>문의유형 선택 단계에서 고객이 종료한 건</li>
                    <li><em>상담 배정중 포기</em><br>문의유형 선택 후 배정받기 전 고객이 종료한 건</li>
                    <li><em>상담 대기중 포기</em><br>배분은 받았지만 대기중인 상태에서 고객이 종료한 건</li>
                    <li><em>전달 대기중 포기</em><br>전달 대기중인 상태에서 고객이 종료한 건</li>
                  </ul>
                </div>
              </v-menu>
            </div>
          </div>
          <div class="is-body">
            <ul class="jh-list-dashboard">
              <li>
                <label>상담 요청중 포기</label>
                <p><em>{{ statusData.NUMBER_CUSTOMER_GIVE_UP_90 }}</em><span class="jh-unit">건</span></p>  
              </li>
              <li>
                <label>상담 배정중 포기</label>
                <p><em>{{ statusData.NUMBER_CUSTOMER_GIVE_UP_91 }}</em><span class="jh-unit">건</span></p>  
              </li>
              <li>
                <label>상담 대기중 포기</label>
                <p><em>{{ statusData.NUMBER_CUSTOMER_GIVE_UP_92 }}</em><span class="jh-unit">건</span></p>  
              </li>
              <li>
                <label>전달 대기중 포기</label>
                <p><em>{{ statusData.NUMBER_CUSTOMER_GIVE_UP_93 }}</em><span class="jh-unit">건</span></p>  
              </li>
            </ul>
          </div>
          
          <div class="is-header">
            <div class="is-left">
              <i class="jh-icon-state is-busy"></i>
              <h6>BUSY</h6>
            </div>
            <div class="is-right">
              <v-menu ref="dropBusy" :close-on-content-click="true" transition="slide-y-transition" offset-y bottom>
                <template v-slot:activator="{ on, attrs }"><v-btn class="jh-btn is-icon" v-bind="attrs" v-on="on"><i class="jh-icon-help-fill is-blue"></i></v-btn></template>
                <div class="jh-drop-menu">
                  <h6><i class="jh-icon-state is-busy"></i>BUSY</h6>
                  <ul class="jh-list-dot">
                    <li><em>상담불가(BUSY) 2가지 조건</em><br>
                      - 업무시간 체크<br>
                      - 휴일 체크</li>
                    <li><em>상담요청불가</em><br>BUSY(상담불가) 2가지 조건 중<br>하나에 걸렸을 경우의 건</li>
                    <li><em>상담요청미선택</em><br>문의유형을 선택하지 않아 시간초과된 건</li>
                    <li><em>상담사배정불가(TimeOut)</em><br>문의유형 선택 후 상담사를 일정 시간안에 배정받지 못해 종료된 건</li>
                  </ul>
                </div>
              </v-menu>
            </div>
          </div>
          <div class="is-body">
            <ul class="jh-list-dashboard">
              <li>
                <label>상담요청불가</label>
                <p><em>{{ statusData.NUMBER_CUSTOMER_GIVE_UP_80 }}</em><span class="jh-unit">건</span></p>  
              </li>
              <!-- <li>
                <label>상담배정불가(SystemClosed)</label>
                <p><em>{{ statusData.NUMBER_CUSTOMER_GIVE_UP_81 }}</em><span class="jh-unit">건</span></p>  
              </li> -->
              <li>
                <label>상담요청미선택</label>
                <p><em>{{ statusData.NUMBER_CUSTOMER_GIVE_UP_90 }}</em><span class="jh-unit">건</span></p>  
              </li>
              <li>
                <label>상담사배정불가(TimeOut)</label>
                <p><em>{{ statusData.NUMBER_CUSTOMER_GIVE_UP_83 }}</em><span class="jh-unit">건</span></p>  
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!--//왼쪽 프레임-->

      <!--오른쪽 프레임-->
      <div>
        <!--현황탭-->
        <div class="jh-form-wrap">
          <ul class="jh-tabs-dashboard">
            <li :class="{ 'is-active': status.accept }" @click="toggleDiv('accept')">
              <span class="is-label"><i class="jh-icon-dashboard is-accept"></i>인입</span>
              <span class="is-value">{{ count.accept }}</span>
            </li>
            <li :class="{ 'is-active': status.acceptinqry }" @click="toggleDiv('acceptinqry')">
              <span class="is-label"><i class="jh-icon-dashboard is-acceptinqry"></i>배정대기</span>
              <span class="is-value">{{ count.acceptinqry }}</span>
            </li>
            <li :class="{ 'is-active': status.ready }" @click="toggleDiv('ready')">
              <span class="is-label"><i class="jh-icon-dashboard is-ready"></i>상담대기</span>
              <span class="is-value">{{ count.ready }}</span>
            </li>
            <li :class="{ 'is-active': status.counsel }" @click="toggleDiv('counsel')">
              <span class="is-label"><i class="jh-icon-dashboard is-counsel"></i>상담중</span>
              <span class="is-value">{{ count.counsel }}</span>
            </li>
            <li :class="{ 'is-active': status.after }" @click="toggleDiv('after')">
              <span class="is-label"><i class="jh-icon-dashboard is-after"></i>후처리</span>
              <span class="is-value">{{ count.after }}</span>
            </li>
          </ul>
          <transition name="slide-down">
            <div class="is-mt-10" v-if="expandTab">
              <div v-if="activeTab === 'accept'">
                <v-data-table
                  class="jh-grid"
                  height="200"
                  fixed-header
                  hide-default-footer
                  no-data-text="현대 데이터가 없습니다."
                  :headers="gridDataHeadersAccept"
                  :items="gridData.gridDataTextAccept"
                  item-key="index"
                  :items-per-page="30"
                  :page.sync="page"
                  @page-count="pageCount = $event"
                ></v-data-table>
              </div>
              <div v-if="activeTab === 'acceptinqry'">
                <v-data-table
                  class="jh-grid"
                  height="200"
                  fixed-header
                  hide-default-footer
                  no-data-text="현대 데이터가 없습니다."
                  :headers="gridDataHeadersAcceptInqry"
                  :items="gridData.gridDataTextAcceptInqry"
                  item-key="index"
                  :page.sync="page"
                  :items-per-page="30"
                  @page-count="pageCount = $event"
                ></v-data-table>
              </div>
              <div v-if="activeTab === 'ready'">
                <v-data-table
                  class="jh-grid"
                  height="200"
                  fixed-header
                  hide-default-footer
                  no-data-text="현대 데이터가 없습니다."
                  :headers="gridDataHeadersReady"
                  :items="gridData.gridDataTextReady"
                  item-key="index"
                  :items-per-page="30"
                  :page.sync="page"
                  @page-count="pageCount = $event"
                ></v-data-table>
              </div>
              <div v-if="activeTab === 'counsel'">
                <v-data-table
                  class="jh-grid"
                  height="200"
                  fixed-header
                  hide-default-footer
                  no-data-text="현대 데이터가 없습니다."
                  :headers="gridDataHeadersCounsel"
                  :items="gridData.gridDataTextCounsel"
                  item-key="index"
                  :items-per-page="30"
                  :page.sync="page"
                  @page-count="pageCount = $event"
                ></v-data-table>
              </div>
              <div v-if="activeTab === 'after'">
                <v-data-table
                  class="jh-grid"
                  height="200"
                  fixed-header
                  hide-default-footer
                  no-data-text="현대 데이터가 없습니다."
                  :headers="gridDataHeadersAfter"
                  :items="gridData.gridDataTextAfter"
                  item-key="index"
                  :items-per-page="30"
                  :page.sync="page"
                  @page-count="pageCount = $event"
                ></v-data-table>
              </div>
            </div>
          </transition>
        </div>
        <!--//현황탭-->

        <div class="jh-form-wrap is-chart">
          <div class="jh-ui-header">
            <h2 class="has-bullet">시간대별 요청 현황</h2>
          </div>            
          <vue-highcharts :options="colume_options_1" ref="columnChart1"></vue-highcharts>
        </div>
        <div class="jh-form-wrap is-chart">
          <div class="jh-ui-header">
            <h2 class="has-bullet">시간대별 완료 현황</h2>
          </div>            
          <vue-highcharts :options="colume_options_2" ref="columnChart2"></vue-highcharts>
        </div>
        <div class="jh-form-wrap is-chart">
          <div class="jh-ui-header">
            <h2 class="has-bullet">문의유형별 상담요청 현황</h2>
          </div>            
          <vue-highcharts :options="colume_options_3" ref="columnChart3"></vue-highcharts>
        </div>
        <div class="jh-form-wrap is-chart" style="visibility: hidden;">
          <div class="jh-ui-header">
            <h2 class="has-bullet">채널별 상담요청 현황</h2>
          </div>            
          <vue-highcharts :options="colume_options_4" ref="columnChart4"></vue-highcharts>
        </div>
      </div>
      <!--//오른쪽 프레임-->
    </div>
  </div>
</template>

<script>
import api from "../../store/apiUtil.js";
import { mixin } from "@/mixin/mixin.js";
import { mapGetters } from "vuex";
import VueHighcharts from "@/components/HighChart";

let resultJson;

const basicData1 = {
  //시간대별 요청현황
  chart: {
    type: "column",
    height: 250
  },
  title: {
    text: "",
  },
  xAxis: {
    categories: [],
    title: {
      text: null,
    },
  },
  yAxis: {
    min: 0,
    title: false,
    labels: {
      overflow: "justify",
    },
  },
  tooltip: {
    valueSuffix: " 건",
  },
  plotOptions: {
    bar: {
      dataLabels: {
        enabled: true,
      },
    },
  },
  legend: {
    layout: "horizontal",
    align: "center",
    verticalAlign: "bottom",
  },
  credits: {
    enabled: false,
  },
  series: [],
};
const basicData2 = {
  //시간대별 완료 현황
  chart: {
    type: "column",
    height: 250
  },
  title: false,
  xAxis: {
    categories: [],
    title: {
      text: null,
    },
  },
  yAxis: {
    min: 0,
    title: false,
    labels: {
      overflow: "justify",
    },
  },
  tooltip: {
    valueSuffix: " 건",
  },
  plotOptions: {
    bar: {
      dataLabels: {
        enabled: true,
      },
    },
  },
  legend: {
    layout: "horizontal",
    align: "center",
    verticalAlign: "bottom",
  },
  credits: {
    enabled: false,
  },
  series: [],
};
const basicData3 = {
  //문의유형별 상담요청 현황
  chart: {
    type: "column",
    height: 250
  },
  title: false,
  xAxis: {
    categories: [],
    title: {
      text: null,
    },
  },
  yAxis: {
    min: 0,
    title: false,
    labels: {
      overflow: "justify",
    },
  },
  tooltip: {
    valueSuffix: " 건",
  },
  plotOptions: {
    bar: {
      dataLabels: {
        enabled: true,
      },
    },
  },
  legend: {
    layout: "horizontal",
    align: "center",
    verticalAlign: "bottom",
  },
  credits: {
    enabled: false,
  },
  series: [],
};
const basicData4 = {
  //채널별 상담요청 현황
  chart: {
    type: "column",
    height: 250
  },
  title: false,
  xAxis: {
    categories: [],
    title: {
      text: null,
    },
  },
  yAxis: {
    min: 0,
    title: false,
    labels: {
      overflow: "justify",
    },
  },
  tooltip: {
    valueSuffix: " 건",
  },
  plotOptions: {
    bar: {
      dataLabels: {
        enabled: true,
      },
    },
  },
  legend: {
    layout: "horizontal",
    align: "center",
    verticalAlign: "bottom",
  },
  credits: {
    enabled: false,
  },
  series: [],
};
export default {
  name: "MENU_E020101", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],
  components: {
    VueHighcharts,
  },
  data: () => ({
    colume_options_1: basicData1,
    colume_options_2: basicData2,
    colume_options_3: basicData3,
    colume_options_4: basicData4,
    status: {
      accept: false,
      acceptinqry: false,
      ready: false,
      counsel: false,
      after: false,
    },
    gridDataHeadersAccept: [
      { text: "채널명", value: "ASP_SENDER_KEY_NM", align: "center", sortable: false },
      { text: "회원/비회원", value: "MEMBER_YN", align: "center", sortable: false },
      { text: "인입시각", value: "TALK_DIST_DT", align: "center", sortable: false },
    ],
    gridDataHeadersAcceptInqry: [
      { text: "채널명", value: "ASP_SENDER_KEY_NM", align: "center", sortable: false },
      { text: "회원/비회원", value: "MEMBER_YN", align: "center", sortable: false, },
      { text: "문의유형", value: "INQRY_TYP_NM", align: "center", sortable: false },
      { text: "요청시각", value: "TALK_DIST_DT", align: "center", sortable: false },
    ],
    gridDataHeadersReady: [
      { text: "채널명", value: "ASP_SENDER_KEY_NM", align: "center", sortable: false },
      { text: "회원/비회원", value: "MEMBER_YN", align: "center", sortable: false, },
      { text: "문의유형", value: "INQRY_TYP_NM", align: "center", sortable: false },
      { text: "상담사", value: "USER_NM", align: "center", sortable: false },
      { text: "배정시각", value: "TALK_READ_DT", align: "center", sortable: false },
      { text: "대기시간", value: "WAIT_TIME", align: "center", sortable: false },
    ],
    gridDataHeadersCounsel: [
      { text: "채널명", value: "ASP_SENDER_KEY_NM", align: "center", sortable: false },
      { text: "회원/비회원", value: "MEMBER_YN", align: "center", sortable: false, },
      { text: "문의유형", value: "INQRY_TYP_NM", align: "center", sortable: false },
      { text: "상담사", value: "USER_NM", align: "center", sortable: false },
      { text: "상담시작시각", value: "TALK_START_DT", align: "center", sortable: false },
      { text: "상담진행시각", value: "COUNSEL_TIME", align: "center", sortable: false },
      { text: "고객무응답시간", value: "NORESPONSETIME", align: "center", sortable: false },
    ],
    gridDataHeadersAfter: [
      { text: "채널명", value: "ASP_SENDER_KEY_NM", align: "center", sortable: false },
      { text: "회원/비회원", value: "MEMBER_YN", align: "center", sortable: false, },
      { text: "문의유형", value: "INQRY_TYP_NM", align: "center", sortable: false },
      { text: "상담사", value: "USER_NM", align: "center", sortable: false },
      { text: "채팅종료시각", value: "TALK_POST_DT", align: "center", sortable: false },
      { text: "후처리소요시간", value: "AFTER_TIME", align: "center", sortable: false },
    ],
    gridData: {
      gridDataTextAccept: [],
      gridDataTextAcceptInqry: [],
      gridDataTextReady: [],
      gridDataTextCounsel: [],
      gridDataTextAfter: [],
    },
    page: 1,
    pageCount: 0,
    itemsPerPage: 5,
    totalVisible: 10,
    selected: "001",
    readyData: {
      READY_COUNSELOR_CNT: "",
      READY_COUNSELOR_TOTALCNT: "",
      COUNSEL_COUNSELOR_CNT:"",
    },

    statusData: {
      NUMBER_INCOMING: "",
      NUMBER_CUSTOMER_REQUEST: "",
      AVG_AWT_RESPONSE: "",
      AVG_AWT_GIVE_UP: "",
      AVG_CHATTING: "",
      AVG_AFTER_TREATMENT: "",
      AVG_CHATTING_AFTER_TREATMENT: "",
      RATE_RESPONSE: "",
      NUMBER_TOT_PROGRESS: "",
      NUMBER_COMPLETE: "",
      NUMBER_CUSTOMER_GIVE_UP_90: "",
      NUMBER_CUSTOMER_GIVE_UP_91: "",
      NUMBER_CUSTOMER_GIVE_UP_92: "",
      NUMBER_CUSTOMER_GIVE_UP_93: "",
      NUMBER_CUSTOMER_GIVE_UP_80: "",
      NUMBER_CUSTOMER_GIVE_UP_81: "",
      NUMBER_CUSTOMER_GIVE_UP_82: "",
      NUMBER_CUSTOMER_GIVE_UP_83: "",
    },
    count: {
      accept: "",
      acceptinqry: "",
      ready: "",
      counsel: "",
      after: "",
    },

    canAgent: "none",

    visibility: {
      accept: "",
      acceptinqry: "",
      ready: "",
      counsel: "",
      after: "",
    },

    recentUpdateTime: "",

    channel_code: [],
    CHART_CHANNEL_NM:[],
    CHART_CHANNEL_CD:[],

    ATTR_A:'',
    ATTR_B:'',
    ATTR_C:'',
    // tog01 : false,
    // tog02 : false,
    // tog03 : false,
    // tog04 : false,
    // tog05 : false,

    counselorList: false,
    activeTab: null,
    expandTab: false,
    // accept: false,
    // acceptinqry: false,
    // ready: false,
    // counsel: false,
    // after: false,
    
  }),
  methods: {
    getUserATTR(){
      //대시보드 데이터 가져오기
        api.post("api/chat/dashboard/ATTR/inqire",   //api url입력
        {
          ASP_NEWCUST_KEY: this.selected,
          USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
        },
        {head: {
          "SERVICE" : "chat.dashboard.ATTR",
          "METHOD" : "inqire",
          "TYPE" : "BIZ_SERVICE",
        }})
        .then((response) => {
          this.ATTR_A = response.data.DATA[0].USER_ATTR_A;
          this.ATTR_B = response.data.DATA[0].USER_ATTR_B;
          this.ATTR_C = response.data.DATA[0].USER_ATTR_C;
          this.afterGetUserATTR();
        })
        .catch((err) => {
          alert(err);
        })
    },

    reflow1() {
      let columnChart = this.$refs.columnChart1;
      setTimeout(() => {
        columnChart.chart.reflow();
        this.setData1();
      }, 500);
    },

    setData1() {
      let columnChart = this.$refs.columnChart1;

      columnChart.removeSeries();

      columnChart.delegateMethod("showLoading", "Loading...");

      let set_data = [];
      let array_data = [];

      for (let i = 0; i < 1/*this.CHART_CHANNEL_CD.length*/; i++) {
        let CHANNEL = "CHECK_ASP_SENDER_KEY_"+this.CHART_CHANNEL_CD[i];

        //대시보드 데이터 가져오기
        api.post("api/chat/dashboard/counsel/rcept-sttus-by-time/inqire",   //api url입력
        {
          ASP_NEWCUST_KEY: this.selected,
          [CHANNEL]: this.CHART_CHANNEL_CD[i],
          USER_ATTR_A: this.ATTR_A,
          USER_ATTR_B: this.ATTR_B,
          USER_ATTR_C: this.ATTR_C,
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
        },
        {head: {
          "SERVICE" : "chat.dashboard.counsel.rcept-sttus-by-time",
          "METHOD" : "inqire",
          "TYPE" : "BIZ_SERVICE",
        }})
        .then((response) => {
          for(let n=0;n<response.data.DATA.length;n++){
            basicData1.xAxis.categories[n] = response.data.DATA[n].TIMES;
            console.log("basicData1.xAxis.categories[n]"+basicData1.xAxis.categories[n]);
            //array_data[n] = response.data.DATA[n].CNT;
            array_data[n] = (typeof response.data.DATA[n].CNT == 'number')? response.data.DATA[n].CNT : parseInt(response.data.DATA[n].CNT);
          }
        })
        .catch((err) => {
          alert(err);
        })

        console.log("basicData1.xAxis.categories"+basicData1.xAxis.categories);
        console.log("basicData1.xAxis.categories"+JSON.stringify(basicData1));

        let inner_set_dat = {};
        let date = this.CHART_CHANNEL_NM; //선택 회사의 채팅 채널
        inner_set_dat["name"] = date[i];
        inner_set_dat["data"] = array_data;
        set_data.push(inner_set_dat);
      }

      setTimeout(() => {
        columnChart.removeSeries();
        set_data.forEach((element) => columnChart.addSeries(element));
        columnChart.hideLoading();
      }, 1000);

      /*
      //다시 호출
      setTimeout(() => {
        this.setData1();
      }, 5000);
      */
    },

    reflow2() {
      let columnChart = this.$refs.columnChart2;
      setTimeout(() => {
        columnChart.chart.reflow();
        this.setData2();
      }, 500);
    },

    setData2() {
      let columnChart = this.$refs.columnChart2;

      columnChart.removeSeries();

      columnChart.delegateMethod("showLoading", "Loading...");

      let set_data = [];
      let array_data = [];

      for (let i = 0; i < 1/*this.CHART_CHANNEL_CD.length*/; i++) {
        let CHANNEL = "CHECK_ASP_SENDER_KEY_"+this.CHART_CHANNEL_CD[i];

        //대시보드 데이터 가져오기
        api.post("api/chat/dashboard/counsel/compt-sttus-by-time/inqire",   //api url입력
        {
          ASP_NEWCUST_KEY: this.selected,
          [CHANNEL]: this.CHART_CHANNEL_CD[i],
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
        },
        {head: {
          "SERVICE" : "chat.dashboard.counsel.compt-sttus-by-time",
          "METHOD" : "inqire",
          "TYPE" : "BIZ_SERVICE",
        }})
        .then((response) => {
          for(let n=0;n<response.data.DATA.length;n++){
            basicData2.xAxis.categories[n] = response.data.DATA[n].TIMES;
            //array_data[n] = response.data.DATA[n].NUMBER_COMPLETE;
            array_data[n] = (typeof response.data.DATA[n].NUMBER_COMPLETE == 'number')? response.data.DATA[n].NUMBER_COMPLETE : parseInt(response.data.DATA[n].NUMBER_COMPLETE);
          }
        })
        .catch((err) => {
          alert(err);
        })

        let inner_set_dat = {};
        let date = this.CHART_CHANNEL_NM; //선택 회사의 채팅 채널
        inner_set_dat["name"] = date[i];
        inner_set_dat["data"] = array_data;
        set_data.push(inner_set_dat);
      }

      setTimeout(() => {
        columnChart.removeSeries();
        set_data.forEach((element) => columnChart.addSeries(element));
        columnChart.hideLoading();
      }, 1000);

      /*
      //다시 호출
      setTimeout(() => {
        this.setData1();
      }, 5000);
      */
    },

    reflow3() {
      let columnChart = this.$refs.columnChart3;
      setTimeout(() => {
        columnChart.chart.reflow();
        this.setData3();
      }, 500);
    },

    setData3() {
      let columnChart = this.$refs.columnChart3;

      columnChart.removeSeries();

      columnChart.delegateMethod("showLoading", "Loading...");

      let set_data = [];
      let array_data = [];

      for (let i = 0; i < 1/*this.CHART_CHANNEL_CD.length*/; i++) {

        //대시보드 데이터 가져오기
        api.post("api/chat/dashboard/counsel/cnslt-requst-sttus-by-svc/inqire",   //api url입력
        {
          ASP_NEWCUST_KEY: this.selected,
          USER_ATTR_A: this.ATTR_A,
          USER_ATTR_B: this.ATTR_B,
          USER_ATTR_C: this.ATTR_C,
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
        },
        {head: {
          "SERVICE" : "chat.dashboard.counsel.cnslt-requst-sttus-by-svc",
          "METHOD" : "inqire",
          "TYPE" : "BIZ_SERVICE",
        }})
        .then((response) => {
          for(let n=0;n<response.data.DATA.length;n++){
            basicData3.xAxis.categories[n] = response.data.DATA[n].INQRY_TYPE;
            //array_data[n] = response.data.DATA[n].CNT;
            array_data[n] = (typeof response.data.DATA[n].CNT == 'number')? response.data.DATA[n].CNT : parseInt(response.data.DATA[n].CNT);
          }
        })
        .catch((err) => {
          alert(err);
        })

        let inner_set_dat = {};
        let date = this.CHART_CHANNEL_NM; //선택 회사의 채팅 채널
        inner_set_dat["name"] = date[i];
        inner_set_dat["data"] = array_data;
        set_data.push(inner_set_dat);
      }

      setTimeout(() => {
        columnChart.removeSeries();
        set_data.forEach((element) => columnChart.addSeries(element));
        columnChart.hideLoading();
      }, 1000);

      /*
      //다시 호출
      setTimeout(() => {
        this.setData1();
      }, 5000);
      */
    },

    reflow4() {
      let columnChart = this.$refs.columnChart4;
      setTimeout(() => {
        columnChart.chart.reflow();
        this.setData4();
      }, 500);
    },

    setData4() {
      let columnChart = this.$refs.columnChart4;

      columnChart.removeSeries();

      columnChart.delegateMethod("showLoading", "Loading...");

      let set_data = [];
      let array_data = [];

      for (let i = 0; i < 1/*this.CHART_CHANNEL_CD.length*/; i++) {
        let CHANNEL = "CHECK_ASP_SENDER_KEY_"+this.CHART_CHANNEL_CD[i];

        //대시보드 데이터 가져오기
        api.post("api/chat/dashboard/counsel/cnslt-requst-sttus-by-plus-frnd/inqire",   //api url입력
        {
          ASP_NEWCUST_KEY: this.selected,
          USER_ATTR_A: this.ATTR_A,
          USER_ATTR_B: this.ATTR_B,
          USER_ATTR_C: this.ATTR_C,
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
        },
        {head: {
          "SERVICE" : "chat.dashboard.counsel.cnslt-requst-sttus-by-plus-frnd",
          "METHOD" : "inqire",
          "TYPE" : "BIZ_SERVICE",
        }})
        .then((response) => {
          for(let n=0;n<response.data.DATA.length;n++){
            basicData4.xAxis.categories[n] = response.data.DATA[n].TALK_SENDER_NM;
            //array_data[n] = response.data.DATA[n].CNT;
            array_data[n] = (typeof response.data.DATA[n].CNT == 'number')? response.data.DATA[n].CNT : parseInt(response.data.DATA[n].CNT);
          }
        })
        .catch((err) => {
          alert(err);
        })

        let inner_set_dat = {};
        let date = this.CHART_CHANNEL_NM; //선택 회사의 채팅 채널
        inner_set_dat["name"] = date[i];
        inner_set_dat["data"] = array_data;
        set_data.push(inner_set_dat);
      }

      setTimeout(() => {
        columnChart.removeSeries();
        set_data.forEach((element) => columnChart.addSeries(element));
        columnChart.hideLoading();
      }, 1000);

      /*
      //다시 호출
      setTimeout(() => {
        this.setData1();
      }, 5000);
      */
    },

    selectValidation(){
      if (this.selected == "" || this.selected == undefined) {
        this.common_alert("회사를 먼저 선택해주세요.","error");
        return false;
      }
      return true;
    },
    async selectMonitoringData() {
      await this.getUserATTR()
    },
    async afterGetUserATTR(){
      //validation check
      if(!this.selectValidation()) return;

      let URLData = "api/chat/dashboard/counsel/dashboard-data/inqire";
      let HEADER_SERVICE = "chat.dashboard.counsel.dashboard-data";
      let HEADER_METHOD = "inqire";
      let HEADER_TYPE = "BIZ_SERVICE";

      let jsonData = {
        ASP_CUST_KEY: this.selected,
        ASP_NEWCUST_KEY: this.selected,
        USER_ATTR_A: this.ATTR_A,
        USER_ATTR_B: this.ATTR_B,
        USER_ATTR_C: this.ATTR_C,
      };

      this.CHART_CHANNEL_CD = [];
      this.CHART_CHANNEL_NM = [];
      this.channel_code = await this.mixin_channel_get(this.selected);
      console.log("1@#2#$3$%"+JSON.stringify(this.channel_code));
      for(let i = 0; i<this.channel_code.length; i++){
        this.CHART_CHANNEL_NM[i] = this.channel_code[0].NM;
        this.CHART_CHANNEL_CD[i] = this.channel_code[0].CD;
      }

      this.setData1();
      this.setData2();
      this.setData3();
      this.setData4();

      await api
        .post(URLData, jsonData, {
          head: {
            ASYNC: "true",
            SERVICE: HEADER_SERVICE,
            METHOD: HEADER_METHOD,
            TYPE: HEADER_TYPE,
          },
        })
        .then((response) => {
          this.readyData.READY_COUNSELOR_TOTALCNT = response.data.DATA[0].READY_COUNSELOR_TOTALCNT;
          this.readyData.READY_COUNSELOR_CNT = response.data.DATA[0].READY_COUNSELOR_CNT;
          this.readyData.COUNSEL_COUNSELOR_CNT = response.data.DATA[0].COUNSEL_COUNSELOR_CNT;

          this.statusData.NUMBER_CUSTOMER_REQUEST = response.data.CONTACT_STATUS[0].NUMBER_CUSTOMER_REQUEST;
          this.statusData.NUMBER_INCOMING = response.data.CONTACT_STATUS[0].NUMBER_INCOMING;
          this.statusData.AVG_AWT_GIVE_UP = response.data.CONTACT_STATUS[0].AVG_AWT_GIVE_UP;
          this.statusData.AVG_AWT_RESPONSE = response.data.CONTACT_STATUS[0].AVG_AWT_RESPONSE;
          this.statusData.AVG_CHATTING = response.data.CONTACT_STATUS[0].AVG_CHATTING;
          this.statusData.AVG_AFTER_TREATMENT = response.data.CONTACT_STATUS[0].AVG_AFTER_TREATMENT;
          this.statusData.AVG_CHATTING_AFTER_TREATMENT = response.data.CONTACT_STATUS[0].AVG_CHATTING_AFTER_TREATMENT;
          this.statusData.RATE_RESPONSE = response.data.CONTACT_STATUS[0].RATE_RESPONSE;
          this.statusData.NUMBER_TOT_PROGRESS = response.data.CONTACT_STATUS[0].NUMBER_TOT_PROGRESS;
          this.statusData.NUMBER_COMPLETE = response.data.CONTACT_STATUS[0].NUMBER_COMPLETE;
          this.statusData.NUMBER_CUSTOMER_GIVE_UP_90 = response.data.CONTACT_STATUS[0].NUMBER_CUSTOMER_GIVE_UP_90;
          this.statusData.NUMBER_CUSTOMER_GIVE_UP_91 = response.data.CONTACT_STATUS[0].NUMBER_CUSTOMER_GIVE_UP_91;
          this.statusData.NUMBER_CUSTOMER_GIVE_UP_92 = response.data.CONTACT_STATUS[0].NUMBER_CUSTOMER_GIVE_UP_92;
          this.statusData.NUMBER_CUSTOMER_GIVE_UP_93 = response.data.CONTACT_STATUS[0].NUMBER_CUSTOMER_GIVE_UP_93;
          this.statusData.NUMBER_CUSTOMER_GIVE_UP_80 = response.data.CONTACT_STATUS[0].NUMBER_CUSTOMER_GIVE_UP_80;
          this.statusData.NUMBER_CUSTOMER_GIVE_UP_81 = response.data.CONTACT_STATUS[0].NUMBER_CUSTOMER_GIVE_UP_81;
          this.statusData.NUMBER_CUSTOMER_GIVE_UP_82 = response.data.CONTACT_STATUS[0].NUMBER_CUSTOMER_GIVE_UP_82;
          this.statusData.NUMBER_CUSTOMER_GIVE_UP_83 = response.data.CONTACT_STATUS[0].NUMBER_CUSTOMER_GIVE_UP_83;

          const jsonObj = JSON.parse(JSON.stringify(response.data.DATA));
          const contactStatus = JSON.parse(JSON.stringify(response.data.CONTACT_STATUS));

          this.statusData = contactStatus[0];
          this.readyData = jsonObj[0];

          //인입, 배정대기, 상담대기, 상담, 후처리 건수 데이터 세팅
          this.setUserReadyStatus("accept");
          this.setUserReadyStatus("acceptinqry");
          this.setUserReadyStatus("ready");
          this.setUserReadyStatus("counsel");
          this.setUserReadyStatus("after");

          //최근 업데이트 날짜 갱신
          this.recentUpdateTime = new Date().toLocaleString();
        })
        .catch((err) => {
          this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
          console.log(err);
        });

    },


    dropDown(){
      if (this.canAgent == "none"){
        this.canAgent = "block";
      } else {
        this.canAgent = "none";
      }
    },

    async setUserReadyStatus(statusType) {
      let URLData = "api/chat/dashboard/counsel/dashboard-table-data/inqire";
      let HEADER_SERVICE = "chat.dashboard.counsel.dashboard-table-data";
      let HEADER_METHOD = "inqire";
      let HEADER_TYPE = "BIZ_SERVICE";

      let jsonData = {
        ASP_CUST_KEY: this.selected,
        ASP_NEWCUST_KEY: this.selected,
        TYPE: statusType,
        USER_ATTR_A: this.ATTR_A,
        USER_ATTR_B: this.ATTR_B,
        USER_ATTR_C: this.ATTR_C,
      };

      await api
        .post(URLData, jsonData, {
          head: {
            SERVICE: HEADER_SERVICE,
            METHOD: HEADER_METHOD,
            TYPE: HEADER_TYPE,
          },
        })
        .then((response) => {
          resultJson = JSON.parse(JSON.stringify(response.data.DATA));
          this.count[statusType] = resultJson.length;
          // for (let key in this.gridData) {
          //   this.gridData[key] = resultJson;
          // }
          if(this.activeTab === statusType){
            if(this.activeTab === 'accept'){
              this.gridData.gridDataTextAccept = resultJson
            }else if(this.activeTab === 'acceptinqry'){
              this.gridData.gridDataTextAcceptInqry = resultJson
            }else if(this.activeTab === 'ready'){
              this.gridData.gridDataTextReady = resultJson
            }else if(this.activeTab === 'counsel'){
              this.gridData.gridDataTextCounsel = resultJson
            }else if(this.activeTab === 'after') {
              this.gridData.gridDataTextAfter = resultJson
            }
          }
        })
        .catch((err) => {
          this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
          console.log(err);
        });
    },
    toggleDiv(statusType) {
      
      for (let key in this.status) {
        this.status[key] = false;
      }
      this.status[statusType] = true;

      if(this.activeTab === statusType){
        this.activeTab = null;
        this.expandTab = false;        
        this.status[statusType] = false;
      }
      else{
        this.activeTab = statusType;
        this.expandTab = true;
        this.setUserReadyStatus(statusType);
      }
    },
    //toggleTooltip
    // toggleTooltip(id) {
    //   if( this[id] === true) {
    //     this[id] = false;
    //     return
    //   }
    //   const arr = ['tog01','tog02','tog03','tog04','tog05' ];
    //   arr.forEach((e) => this[e] = false );
    //   this[id] = !this[id];
    // },       
    handleScroll (e) {
      this.scrollTop = e.target.scrollTop;
      if(this.scrollTop > 50){
        this.$refs.dropReady.isActive = false;
        this.$refs.dropCounsel.isActive = false;
        this.$refs.dropComplete.isActive = false;
        this.$refs.dropGiveup.isActive = false;
        this.$refs.dropBusy.isActive = false;
      }
    },
  },
  computed: {
    ...mapGetters({
      aspCustList: "userStore/GE_USER_COMPANY",
    }),
  },
  mounted(){
    for(let i in this.visibility){
      this.visibility[i] = "none";
    }

    this.selectMonitoringData()
  },
  created: function () {
      document.querySelector('.jh-layout-contents').addEventListener('scroll', this.handleScroll);
  },
  destroyed: function () {
      document.querySelector('.jh-layout-contents').removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style>
.v-btn.jh-btn.is-icon:before{
  display: none;
}
</style>
